import { Injectable } from '@angular/core';
import { find } from 'lodash';





// We have a definied priority and wait for each of those overlays to be checked,
// once the first is checked with a valid skip message TRUE or FALSE, we allow the next one to be checked and Visible or not

// IMPORTANT: Those Views needs to be activated!

const OVERLAY_ORDER: {
  order: number;
  name: string;
  skip: boolean | undefined;
  callback: any;
}[] = [{
    order: 0,   // will always be called and checked!
    name: 'user_setup_finish',
    skip : undefined,
    callback : undefined
  },
  {
    order: 1,   // will always be called and checked!
    name: 'validate_support_ticket',
    skip : undefined,
    callback : undefined
  },
  /*
  {
    order: 2,    // will always be called and checked!
    name: 'show_disruptor',
    // TODO: Set to skip: undefined, when Disruptor is enabled, and skip: true when it is disabled!!!
    skip: undefined,
    callback : undefined
  },
  */
  {
    order: 2,    // will always be called and checked!
    name: 'news_disruptor',
    skip: undefined,
    callback : undefined
  },
  {
    order: 3,  // will always be visible as the last step
    name: 'nps',
    skip : undefined,
    callback : undefined
  }
];


@Injectable({
  providedIn: 'root'
})
export class OverlayManagementService {


  currentStep = 0;


  private getStepByName(_name: string) {
    return find(OVERLAY_ORDER, {name: _name}) || false;
  }


  /**
   * Every Component reports if it want's to be visible or not, if one want's to be visible ...
   *
   *
   * @param _name
   * @param _status
   */
  reportStatus(_name: string, _callback_or_false: any) {

    // Store Data and work on queue
    const step:any = this.getStepByName(_name);
    if (step !== false) {
      if (_callback_or_false === false) {
        step.skip = true;
      } else {
        step.skip = false;
        step.callback = _callback_or_false;
      }
    }


     this.next();

  }



  /**
   * Checks if current Step has alrady reported the status
   */
  next(_increase = false){

    if (_increase === true) {
      this.currentStep = this.currentStep + 1;
    }

    const currentStepConfig = OVERLAY_ORDER[ this.currentStep ];

    if ( currentStepConfig && currentStepConfig.skip !== undefined ) {
      // We have any Data
      if (currentStepConfig.skip){
        this.currentStep = this.currentStep + 1;
        this.next();
      } else {
        currentStepConfig.callback();
      }

    } else {
      // console.log('Yet no Stat for current Step');
    }

  }



}
